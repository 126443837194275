.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*LOGIN Form*/
.container .x {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #343a40;
}

.login-form .form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.login-form .form-control:focus {
  box-shadow: none;
  border-color: #007bff;
}

.login-form .btn-primary {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  width: 100%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
}

.login-form .btn-primary:hover {
  /*background-color: #0056b3;*/
  cursor: pointer;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
}
.login-form .btn-primary:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.login-form .btn-primary:focus {
  box-shadow: none;
}

/* Center the form on small screens */
@media (max-width: 576px) {
  .login-form {
    width: 100%;
  }
}
