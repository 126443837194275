.link_deck {
  text-decoration: none;
}
.list-group-item {
  border: none;
  border-radius: 12px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.list-group-item:hover {
  transform: translateY(-2px);
}

.list-group-item a {
  text-decoration: none; /* Remove the underline here */
  color: #333;
}

.list-group-item a:hover {
  /* No underline on hover as well */
  text-decoration: none;
}

.list-group-item::before {
  content: "📌";
  margin-right: 8px;
  font-size: 18px;
}

.list-group-item .item-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}
