.card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-img-top {
  border-radius: 12px 12px 0 0;
  height: 200px;
  object-fit: cover;
}

.card-body {
  padding: 16px;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8px;
}

.card-link {
  display: block;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  padding: 12px;
  border-radius: 12px 12px 12px 12px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.card-link:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.card-link:focus {
  box-shadow: none;
  outline: none;
}
